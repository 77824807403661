import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { Tags } from "@styled-icons/bootstrap/"
import { Table } from "@styled-icons/bootstrap/Table"
import { CalendarOutline } from "@styled-icons/evaicons-outline/CalendarOutline"
import { ThinkPeaks } from "@styled-icons/fa-brands/ThinkPeaks"
import { ChartBar } from "@styled-icons/fa-regular/ChartBar"
import { Clock } from "@styled-icons/fa-regular/Clock"
import { GraphTrend } from "@styled-icons/foundation/GraphTrend"
import { Monitor } from "@styled-icons/foundation/Monitor"
import { MoneyOff } from "@styled-icons/material/MoneyOff"
import { LuggageDeposit } from "@styled-icons/remix-line/LuggageDeposit"
import { MoneyDollarCircle } from "@styled-icons/remix-line/MoneyDollarCircle"
import { LoadBalancer } from "@styled-icons/zondicons/LoadBalancer"
import styled, { css, StyledComponent } from "styled-components"
import { BrainCircuit } from "styled-icons/fluentui-system-regular"
import { SortAmountAsc } from "styled-icons/icomoon"
import { Exposure, PriceChange } from "styled-icons/material-outlined"
import { ReactComponent as Blocks } from "../icons/blocks.svg"
import { ReactComponent as Rocket } from "../icons/rocket.svg"
import { ReactComponent as Save } from "../icons/save.svg"
import { ReactComponent as Bank } from "../icons/widget-icon-bank.svg"
import { ReactComponent as Compass } from "../icons/widget-icon-compass.svg"
import { ReactComponent as CrownIcon } from "../icons/widget-icon-crown.svg"
import { ReactComponent as Gold } from "../icons/widget-icon-gold.svg"
import { ReactComponent as PieChart } from "../icons/widget-icon-piechart.svg"
import { ReactComponent as Stock } from "../icons/widget-icon-stock.svg"

export const breakpoint = {
  siteWidth: "1900px",
  verylarge: "1650px",
  large: "1400px",
  medium: "1150px",
  bigtablet: "900px",
  tablet: "768px",
  smalltablet: "600px",
  mobile: "480px",
} as const

export const breakpointNum = {
  siteWidth: 1900,
  verylarge: 1650,
  large: 1400,
  medium: 1150,
  bigtablet: 900,
  tablet: 768,
  smalltablet: 600,
  mobile: 480,
} as const

export const widgetLayouts = {
  headerHeightPx: 34,
  headerSmallHeightPx: 30,
  headerIconDimPx: 30,
  headerSmallIconDimPx: 26,
  subHeaderHeightPx: 34,
  subHeaderSmallHeightPx: 30,
  subHeaderRoundButtonDimPx: 21,
  subHeaderRoundSmallButtonDimPx: 18,
  subHeaderRoundGraphIconDimPx: 18,
  subHeaderRoundSmallGraphIconDimPx: 14,
}

export const colors = {
  iBlack: "#111212",
  iUltraDarkGrey: "#212327",
  iDarkGrey: "#343843",
  iMediumGrey: "#94959D",
  iLightGrey: "#C3C7C6",
  iUltraLightGrey: "#E9E9EC",
  iGrey01: "#555965",
  iGrey02: "#7D7F85",
  iGreen: "#1E8D43",
  iGreenText: "#39BF66",
  iBrightGreen: "#36be2c",
  iRed: "#BF2C2C",
  iRedText: "#FA3F3F",
  iPrimaryBlue: "#74A2FF",
  iBlue00: "#6892E6",
  iBlue01: "#5D82CC",
  iBlue02: "#5171B3",
  iBlue03: "#466199",
  iBlue04: "#82ABFF",
  iBlue05: "#90B5FF",
  iBlue06: "#9EBEFF",
  iBlue07: "#ACC7FF",
  iPurple: "#C884FC",
  iPurple01: "#DEB5FD",
  iPurple02: "#D9A9FD",
  iPurple03: "#D39DFD",
  iPurple04: "#CE90FC",
  iPurple05: "#B477E3",
  iPurple06: "#A06ACA",
  iPurple07: "#8C5CB0",
  iPurple08: "#784F97",
  iOrange: "#FE9E44",
  iOrange01: "#985F29",
  iOrange02: "#B26F30",
  iOrange03: "#CB7E36",
  iOrange04: "#E58E3D",
  iOrange05: "#FEA857",
  iOrange06: "#FEB169",
  iOrange07: "#FEBB7C",
  iOrange08: "#FEC58F",
  iOrange09: "#dfa425",
  iOrange10: "#ec6e1b",
  iYellow: "#E0C31A",
  iWhite: "#FFFFFF",
  iTransparent: "#FFFFFF00",
} as const

type IconStyleProps = {
  fill?: string
  margin?: number
  width?: number
}

const iconsStyles = css<IconStyleProps>`
  color: ${colors.iMediumGrey};
  fill: ${props => props.fill};
  * {
    fill: ${props => props.fill};
  }
  margin: ${props => props.margin || 0}px;
  width: ${props => props.width || 22}px;
`

export const TagsStyled = styled(Tags)`
  ${iconsStyles}
  transform: scaleX(-1); // to mirror the image
`
TagsStyled.defaultProps = {
  title: "Liquidity Book",
}

export const RocketStyled = styled(Rocket)`
  ${iconsStyles}
`
RocketStyled.defaultProps = {
  title: "PEP graph",
}

export const CrownIconStyled = styled(CrownIcon)`
  ${iconsStyles}
`
CrownIconStyled.defaultProps = {
  title: "TOB Dominance",
}

export const GoldStyled = styled(Gold)`
  ${iconsStyles}
`
GoldStyled.defaultProps = {
  title: "TOB Contribution",
}

export const ChartBarStyled = styled(ChartBar)`
  ${iconsStyles}
`
ChartBarStyled.defaultProps = {
  title: "Spread Counter",
}

export const StockStyled = styled(Stock)`
  ${iconsStyles}
`
StockStyled.defaultProps = {
  title: "Spread Graph",
}

export const CompassStyled = styled(Compass)`
  ${iconsStyles}
`
CompassStyled.defaultProps = {
  title: "Open Positions",
}

export const MonitorStyled = styled(Monitor)`
  ${iconsStyles}
`
CompassStyled.defaultProps = {
  title: "Spread Monitor",
}

export const BlocksStyled = styled(Blocks)`
  ${iconsStyles}
`
BlocksStyled.defaultProps = {
  title: "",
}

export const SaveStyled = styled(Save)`
  ${iconsStyles}
`
SaveStyled.defaultProps = {
  title: "",
}

export const BankStyled = styled(Bank)`
  ${iconsStyles}
`
BankStyled.defaultProps = {
  title: "Balance & Equity",
}

export const PieChartStyled = styled(PieChart)`
  ${iconsStyles}
`
PieChartStyled.defaultProps = {
  title: "Currency Exposure",
}

export const ClockStyled = styled(Clock)`
  ${iconsStyles}
`
ClockStyled.defaultProps = {
  title: "Quote freshness",
}

export const BrainStyled = styled(BrainCircuit)`
  ${iconsStyles}
`

export const PnlIconStyled = styled(MoneyDollarCircle)`
  ${iconsStyles}
`

export const POPIconStyled = styled(LoadBalancer)`
  ${iconsStyles}
`

export const VolumeIconStyled = styled(LuggageDeposit)`
  ${iconsStyles}
`

export const CostIconStyled = styled(MoneyOff)`
  ${iconsStyles}
`
CostIconStyled.defaultProps = {
  role: "img",
}

export const PopOrBrokerTableIconStyled = styled(Table)`
  ${iconsStyles}
`

export const MarginExposureIconStyled = styled(Exposure)`
  ${iconsStyles}
`

export const PairsExposureIconStyled = styled(ThinkPeaks)`
  ${iconsStyles}
`
export const PairsVolumeIconStyled = styled(SortAmountAsc)`
  ${iconsStyles}
`

export const PriceChangeStyled = styled(PriceChange)`
  ${iconsStyles}
`
PriceChangeStyled.defaultProps = {
  title: "Trading View prices",
}

export const ExternalCalendarStyled = styled(CalendarOutline)`
  ${iconsStyles}
`
ExternalCalendarStyled.defaultProps = {
  title: "External Calendar",
}

export const MarketOverviewStyled = styled(GraphTrend)`
  ${iconsStyles}
`
MarketOverviewStyled.defaultProps = {
  title: "Market Overview",
}

export const MinusStyled = styled(MinusCircleOutlined)`
  ${iconsStyles}
  color:${colors.iWhite};
`

export const PlusStyled = styled(PlusCircleOutlined)`
  ${iconsStyles}
  color:${colors.iDarkGrey};
`

BrainStyled.defaultProps = {
  title: "AI Activity",
}

export const GraphIcon = ({
  icon,
  width,
}: {
  icon: StyledComponent<any, any> | undefined
  width?: number
}) => {
  if (!icon) return null
  const Icon = icon
  return <Icon style={{ color: colors.iPrimaryBlue, margin: 5, width }} />
}
