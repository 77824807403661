import { StyledComponent } from "styled-components"
import { z } from "zod"
import {
  BankStyled,
  BrainStyled,
  ChartBarStyled,
  ClockStyled,
  CompassStyled,
  CostIconStyled,
  CrownIconStyled,
  ExternalCalendarStyled,
  GoldStyled,
  MarginExposureIconStyled,
  MarketOverviewStyled,
  MonitorStyled,
  PairsExposureIconStyled,
  PairsVolumeIconStyled,
  PieChartStyled,
  PopOrBrokerTableIconStyled,
  PriceChangeStyled,
  RocketStyled,
  StockStyled,
  TagsStyled,
} from "../styles"
import { Broker, GraphOptions, GraphType } from "../types"

export const AllGraphs = [
  "liquidity",
  "spread",
  "spreadCounter",
  "TOBContribution",
  "TOBDominance",
  "openPositions",
  "balanceEquity",
  "currencyExposure",
  "pep",
  "pep-beta",
  "quotesDelays",
  "aiActivity",
  "aiActivity-2",
  "aiActivityLine",
  "spreadAverages",
  "costs",
  "popOrBrokerTable",
  "exposureMargin",
  "exposurePairs",
  "volumePairs",
  "externalPrices",
  "externalEconomicCalendar",
  "externalMarketOverview",
] as const

export const pairList = [
  {
    id: "00_eurusd",
    label: "EURUSD",
    fullName: "EURO/UNITED STATES DOLLAR",
    bpPrecision: 5,
  },
  {
    id: "01_audusd",
    label: "AUDUSD",
    fullName: "AUSTRALIAN DOLLAR/UNITED STATES DOLLAR",
    bpPrecision: 5,
  },
  {
    id: "02_eurchf",
    label: "EURCHF",
    fullName: "EURO/SWISS FRANC",
    bpPrecision: 5,
  },
  {
    id: "03_eurgbp",
    label: "EURGBP",
    fullName: "EURO/BRITISH POUNDS",
    bpPrecision: 5,
  },
  {
    id: "04_eurjpy",
    label: "EURJPY",
    fullName: "EURO/JAPANESE YEN",
    bpPrecision: 3,
  },
  {
    id: "05_gbpusd",
    label: "GBPUSD",
    fullName: "BRITISH POUND/UNITED STATES DOLLAR",
    bpPrecision: 5,
  },
  {
    id: "06_nzdusd",
    label: "NZDUSD",
    fullName: "NEW ZEALAND DOLLAR/UNITED STATES DOLLAR",
    bpPrecision: 5,
  },
  {
    id: "07_usdcad",
    label: "USDCAD",
    fullName: "UNITED STATES DOLLAR/CANADIAN DOLLAR",
    bpPrecision: 5,
  },
  {
    id: "08_usdchf",
    label: "USDCHF",
    fullName: "UNITED STATES DOLLAR/SWISS FRANC",
    bpPrecision: 5,
  },
  {
    id: "09_usdjpy",
    label: "USDJPY",
    fullName: "UNITED STATES DOLLAR/JAPANESE YEN",
    bpPrecision: 3,
  },
  {
    id: "10_audchf",
    label: "AUDCHF",
    fullName: "AUSTRALIAN DOLLAR/SWISS FRANC",
    bpPrecision: 5,
  },
  {
    id: "11_audjpy",
    label: "AUDJPY",
    fullName: "AUSTRALIAN DOLLAR/JAPANESE YEN",
    bpPrecision: 3,
  },
  {
    id: "12_cadchf",
    label: "CADCHF",
    fullName: "CANADIAN DOLLAR/SWISS FRANC",
    bpPrecision: 5,
  },
  {
    id: "13_cadjpy",
    label: "CADJPY",
    fullName: "CANADIAN DOLLAR/JAPANESE YEN",
    bpPrecision: 3,
  },
  {
    id: "14_chfjpy",
    label: "CHFJPY",
    fullName: "SWISS FRANC/JAPANESE YEN",
    bpPrecision: 3,
  },
  {
    id: "15_euraud",
    label: "EURAUD",
    fullName: "EURO/AUSTRALIAN DOLLAR",
    bpPrecision: 5,
  },
  {
    id: "16_eurcad",
    label: "EURCAD",
    fullName: "EURO/CANADIAN DOLLAR",
    bpPrecision: 5,
  },
  {
    id: "17_gbpaud",
    label: "GBPAUD",
    fullName: "BRITISH POUND/AUSTRALIAN DOLLAR",
    bpPrecision: 5,
  },
  {
    id: "18_gbpcad",
    label: "GBPCAD",
    fullName: "BRITISH POUND/CANADIAN DOLLAR",
    bpPrecision: 5,
  },
  {
    id: "19_gbpjpy",
    label: "GBPJPY",
    fullName: "BRITISH POUND/JAPANESE YEN",
    bpPrecision: 3,
  },
  {
    id: "20_nzdcad",
    label: "NZDCAD",
    fullName: "NEW ZEALAND DOLLAR/CANADIAN DOLLAR",
    bpPrecision: 5,
  },
  {
    id: "21_nzdchf",
    label: "NZDCHF",
    fullName: "NEW ZEALAND DOLLAR/SWISS FRANC",
    bpPrecision: 5,
  },
  {
    id: "22_nzdjpy",
    label: "NZDJPY",
    fullName: "NEW ZEALAND DOLLAR/JAPANESE YEN",
    bpPrecision: 3,
  },
  {
    id: "23_usdsgd",
    label: "USDSGD",
    fullName: "UNITED STATES DOLLAR/SINGAPORE DOLLAR",
    bpPrecision: 5,
  },
  {
    id: "24_audcad",
    label: "AUDCAD",
    fullName: "AUSTRALIAN DOLLAR/CANADIAN DOLLAR",
    bpPrecision: 5,
  },
  {
    id: "25_audnzd",
    label: "AUDNZD",
    fullName: "AUSTRALIAN DOLLAR/NEW ZEALAND DOLLAR",
    bpPrecision: 5,
  },
  {
    id: "26_eurnzd",
    label: "EURNZD",
    fullName: "EURO/NEW ZEALAND DOLLAR",
    bpPrecision: 5,
  },
  {
    id: "27_gbpchf",
    label: "GBPCHF",
    fullName: "BRITISH POUND/SWISS FRANC",
    bpPrecision: 5,
  },
  {
    id: "28_gbpnzd",
    label: "GBPNZD",
    fullName: "BRITISH POUND/NEW ZEALAND DOLLAR",
    bpPrecision: 5,
  },
  {
    id: "29_audsgd",
    label: "AUDSGD",
    fullName: "AUSTRALIAN DOLLAR/SINGAPORE DOLLAR",
    bpPrecision: 5,
  },
  {
    id: "30_chfsgd",
    label: "CHFSGD",
    fullName: "SWISS FRANC/SINGAPORE DOLLAR",
    bpPrecision: 5,
  },
  {
    id: "31_eurdkk",
    label: "EURDKK",
    fullName: "EURO/DANISH KRONE",
    bpPrecision: 5,
  },
  {
    id: "32_eurhkd",
    label: "EURHKD",
    fullName: "EURO/HONG KONG DOLLAR",
    bpPrecision: 5,
  },
  {
    id: "33_eurnok",
    label: "EURNOK",
    fullName: "EURO/NORWEGIAN KRONE",
    bpPrecision: 5,
  },
  {
    id: "34_eurpln",
    label: "EURPLN",
    fullName: "EURO/POLISH ZLOTY",
    bpPrecision: 5,
  },
  {
    id: "35_eursek",
    label: "EURSEK",
    fullName: "EURO/SWEDISH KRONA",
    bpPrecision: 5,
  },
  {
    id: "36_eursgd",
    label: "EURSGD",
    fullName: "EURO/SINGAPORE DOLLAR",
    bpPrecision: 5,
  },
  {
    id: "37_eurtry",
    label: "EURTRY",
    fullName: "EURO/TURKISH LIRA",
    bpPrecision: 5,
  },
  {
    id: "38_eurzar",
    label: "EURZAR",
    fullName: "EURO/SOUTH AFRICAN RAND",
    bpPrecision: 5,
  },
  {
    id: "39_gbpdkk",
    label: "GBPDKK",
    fullName: "BRITISH POUND/DANISH KRONE",
    bpPrecision: 5,
  },
  {
    id: "40_gbpnok",
    label: "GBPNOK",
    fullName: "BRITISH POUND/NORWEGIAN KRONE",
    bpPrecision: 5,
  },
  {
    id: "41_gbpsek",
    label: "GBPSEK",
    fullName: "BRITISH POUND/SWEDISH KRONA",
    bpPrecision: 5,
  },
  {
    id: "42_gbpsgd",
    label: "GBPSGD",
    fullName: "BRITISH POUND/SINGAPORE DOLLAR",
    bpPrecision: 5,
  },
  {
    id: "43_gbptry",
    label: "GBPTRY",
    fullName: "BRITISH POUND/TURKISK LIRA",
    bpPrecision: 5,
  },
  {
    id: "44_nokjpy",
    label: "NOKJPY",
    fullName: "NORWEGIAN KRONE/JAPANESE YEN",
    bpPrecision: 3,
  },
  {
    id: "45_noksek",
    label: "NOKSEK",
    fullName: "NORWEGIAN KRONE/SWEDISH KRONA",
    bpPrecision: 5,
  },
  {
    id: "46_sekjpy",
    label: "SEKJPY",
    fullName: "SWEDISH KRONA/JAPANESE YEN",
    bpPrecision: 3,
  },
  {
    id: "47_sgdjpy",
    label: "SGDJPY",
    fullName: "SINGAPORE DOLLAR/JAPANESE YEN",
    bpPrecision: 3,
  },
  {
    id: "48_usdcnh",
    label: "USDCNH",
    fullName: "UNITED STATES DOLLAR/CHINA OFFSHORE SPOT",
    bpPrecision: 5,
  },
  {
    id: "49_usdczk",
    label: "USDCZK",
    fullName: "UNITED STATES DOLLAR/CZECH KORUNA",
    bpPrecision: 5,
  },
  {
    id: "50_usddkk",
    label: "USDDKK",
    fullName: "UNITED STATES DOLLAR/DANISH KRONE",
    bpPrecision: 5,
  },
  {
    id: "51_usdhkd",
    label: "USDHKD",
    fullName: "UNITED STATES DOLLAR/HONG KONG DOLLAR",
    bpPrecision: 5,
  },
  {
    id: "52_usdhuf",
    label: "USDHUF",
    fullName: "UNITED STATES DOLLAR/HUNGARIAN FORINT",
    bpPrecision: 5,
  },
  {
    id: "53_usdmxn",
    label: "USDMXN",
    fullName: "UNITED STATES DOLLAR/MEXICAN PESO",
    bpPrecision: 5,
  },
  {
    id: "54_usdnok",
    label: "USDNOK",
    fullName: "UNITED STATES DOLLAR/NORWEGIAN KRONE",
    bpPrecision: 5,
  },
  {
    id: "55_usdpln",
    label: "USDPLN",
    fullName: "UNITED STATES DOLLAR/POLISH ZLOTY",
    bpPrecision: 5,
  },
  {
    id: "56_usdrub",
    label: "USDRUB",
    fullName: "UNITED STATES DOLLAR/RUSSIAN RUBLE",
    bpPrecision: 5,
  },
  {
    id: "57_usdsek",
    label: "USDSEK",
    fullName: "UNITED STATES DOLLAR/SWEDISH KRONA",
    bpPrecision: 5,
  },
  {
    id: "58_usdthb",
    label: "USDTHB",
    fullName: "UNITED STATES DOLLAR/THAI BAHT",
    bpPrecision: 5,
  },
  {
    id: "59_usdtry",
    label: "USDTRY",
    fullName: "UNITED STATES DOLLAR/TURKISH LIRA",
    bpPrecision: 5,
  },
  {
    id: "60_usdzar",
    label: "USDZAR",
    fullName: "UNITED STATES DOLLAR/SOUTH AFRICAN RAND",
    bpPrecision: 5,
  },
  {
    id: "61_xageur",
    label: "XAGEUR",
    fullName: "SILVER OUNCES/EURO",
    bpPrecision: 2,
  },
  {
    id: "62_xagusd",
    label: "XAGUSD",
    bpPrecision: 3,
    fullName: "SILVER OUNCES/UNITED STATES DOLLAR",
  },
  {
    id: "63_xauaud",
    label: "XAUAUD",
    bpPrecision: 2,
    fullName: "GOLD OUNCES/AUSTRALIAN DOLLAR",
  },
  {
    id: "64_xaueur",
    label: "XAUEUR",
    bpPrecision: 2,
    fullName: "GOLD OUNCES/EURO",
  },
  {
    id: "65_xauusd",
    label: "XAUUSD",
    fullName: "GOLD OUNCES/UNITED STATES DOLLAR",
    bpPrecision: 2,
  },
  {
    id: "66_xbrusd",
    label: "XBRUSD",
    bpPrecision: 5,
    fullName: "UK BRENT/UNITED STATES DOLLAR",
  },
  {
    id: "67_xngusd",
    label: "XNGUSD",
    bpPrecision: 5,
    fullName: "US NATURAL GAS/UNITED STATES DOLLAR",
  },
  {
    id: "68_xpdusd",
    label: "XPDUSD",
    bpPrecision: 5,
    fullName: "PALLADIUM/UNITED STATES DOLLAR",
  },
  {
    id: "69_xptusd",
    label: "XPTUSD",
    bpPrecision: 5,
    fullName: "PLATINUM/UNITED STATES DOLLAR",
  },
  {
    id: "70_xtiusd",
    label: "XTIUSD",
    bpPrecision: 5,
    fullName: "US WTI CRUDE/UNITED STATES DOLLAR",
  },
  {
    id: "71_btcusd",
    label: "BTCUSD",
    fullName: "BITCOIN/UNITED STATES DOLLAR",
    bpPrecision: 1,
  },
  /*
  {
    id: "72_200aud",
    label: "200AUD",
    fullName: "200AUD",
    bpPrecision: 5,
  },
  {
    id: "74_d30eur",
    label: "D30EUR",
    fullName: "D30EUR",
    bpPrecision: 5,
  },
  {
    id: "75_e35eur",
    label: "E35EUR",
    fullName: "E35EUR",
    bpPrecision: 5,
  },
  {
    id: "76_f40eur",
    label: "F40EUR",
    fullName: "F40EUR",
    bpPrecision: 5,
  },
  {
    id: "79_225jpy",
    label: "255JPY",
    fullName: "255JPY",
    bpPrecision: 3,
  },
  {
    id: "80_e50eur",
    label: "E50EUR",
    fullName: "E50EUR",
    bpPrecision: 5,
  },
  {
    id: "81_100gbp",
    label: "100GBP",
    fullName: "100GBP",
    bpPrecision: 5,
  },
  {
    id: "83_u30usd",
    label: "U30USD",
    fullName: "U30USD",
    bpPrecision: 5,
  },
  {
    id: "84_spxusd",
    label: "SPXUSD",
    fullName: "SPXUSD",
    bpPrecision: 5,
  },
  {
    id: "85_nasusd",
    label: "NASUSD",
    fullName: "NASUSD",
    bpPrecision: 5,
  },
  */
  {
    id: "86_ethusd",
    label: "ETHUSD",
    bpPrecision: 5,
    fullName: "ETHEREUM/UNITED STATE DOLLAR",
  },
  {
    id: "87_chfpln",
    label: "CHFPLN",
    bpPrecision: 5,
    fullName: "SWISS FRANK/POLISH ZLOTY",
  },
  {
    id: "88_eurczk",
    label: "EURCZK",
    bpPrecision: 5,
    fullName: "EURO/CZECH KORUNY",
  },
  {
    id: "89_eurhuf",
    label: "EURHUF",
    bpPrecision: 5,
    fullName: "EURO/HUNGARIAN FORINT",
  },
  {
    id: "90_eurils",
    label: "EURILS",
    bpPrecision: 5,
    fullName: "EURO/ISRAELI NEW SHEKELS",
  },
  {
    id: "91_eurmxn",
    label: "EURMXN",
    bpPrecision: 5,
    fullName: "EURO/MEXICAN PESO",
  },
  {
    id: "92_gbphuf",
    label: "GBPHUF",
    bpPrecision: 5,
    fullName: "BRITISH POUND/HUNGARIAN FORINT",
  },
  {
    id: "93_gbppln",
    label: "GBPPLN",
    bpPrecision: 5,
    fullName: "BRITISH POUND/POLISH ZLOTY",
  },
  {
    id: "94_tryjpy",
    label: "TRYJPY",
    bpPrecision: 3,
    fullName: "TURKISH LIRA/JAPANESE YEN",
  },
  {
    id: "95_gbpzar",
    label: "GBPZAR",
    bpPrecision: 5,
    fullName: "BRITISH POUND/SOUTH AFRICAN RAND",
  },
  {
    id: "96_nzdhuf",
    label: "NZDHUF",
    bpPrecision: 5,
    fullName: "NEW ZELAND DOLLAR/HUNGARIAN FORINT",
  },
  {
    id: "97_usdils",
    label: "USDILS",
    bpPrecision: 5,
    fullName: "UNITED STATES DOLLAR/ISRAELI NEW SHEKELS",
  },
  {
    id: "98_zarjpy",
    label: "ZARJPY",
    bpPrecision: 3,
    fullName: "SOUTH AFRICAN RAND/JAPANESE YEN",
  },
  {
    id: "99_eurrub",
    label: "EURRUB",
    bpPrecision: 5,
    fullName: "EURO/RUSSIAN RUBLE",
  },
  {
    id: "100_hkdjpy",
    label: "HKDJPY",
    bpPrecision: 3,
    fullName: "HONG KONG DOLLAR/JAPANESE YEN",
  },
  {
    id: "101_mxnjpy",
    label: "MXNJPY",
    bpPrecision: 3,
    fullName: "MEXICAN PESO/JAPANESE YEN",
  },
  {
    id: "102_nzdsgd",
    label: "NZDSGD",
    bpPrecision: 5,
    fullName: "NEW ZELAND DOLLAR/SINGAPORE DOLLAR",
  },
  {
    id: "103_usdbrl",
    label: "USDBRL",
    bpPrecision: 5,
    fullName: "UNITED STATE DOLLAR/BRASILIAN REAL",
  },
  {
    id: "104_usdron",
    label: "USDRON",
    bpPrecision: 5,
    fullName: "UNITED STATE DOLLAR/ROMANIAN LEU",
  },
  { id: "all", label: "ALL PAIRS", bpPrecision: 5, fullName: "Pairs Sum" },
] as const

export const pairIdValidator = z.enum([
  pairList[0].id,
  ...pairList.slice(1).map(p => p.id),
]) //the first value is read manually to ensure that the list is not empty

export const graphsMenuItems: {
  label: string
  icon: StyledComponent<any, any>
  graph: GraphType
  options: GraphOptions
  maxItems?: number
}[] = [
  {
    label: "Liquidity Book",
    icon: TagsStyled,
    graph: "liquidity",
    options: {
      brokers: {
        allIndividualFeeds: true,
        aggregated: true,
      },
      pairs: {
        allIndividual: true,
      },
    },
  },
  {
    label: "TOB Dominance",
    icon: CrownIconStyled,
    graph: "TOBDominance",
    options: {
      brokers: {
        aggregated: true,
      },
      pairs: {
        allIndividual: true,
      },
    },
  },
  {
    label: "TOB Contribution",
    icon: GoldStyled,
    graph: "TOBContribution",
    options: {
      brokers: {
        tradedBrokers: true,
      },
      pairs: {
        allIndividual: true,
      },
    },
  },
  {
    label: "Spread Counter",
    icon: ChartBarStyled,
    graph: "spreadCounter",
    options: {
      brokers: {
        allIndividualFeeds: true,
        aggregated: true,
      },
      pairs: {
        allIndividual: true,
      },
    },
  },
  {
    label: "AI Activity Graph",
    icon: BrainStyled,
    graph: "aiActivity",
    options: {
      brokers: {
        aggregated: true,
      },
      pairs: {
        onlyUsedForTrading: true,
      },
    },
    maxItems: 5,
  },
  {
    label: "AI Activity Graph - 2",
    icon: BrainStyled,
    graph: "aiActivity-2",
    options: {
      brokers: {
        aggregated: true,
      },
      pairs: {
        onlyUsedForTrading: true,
      },
    },
    maxItems: 5,
  },
  {
    label: "AI Activity Line Graph",
    icon: BrainStyled,
    graph: "aiActivityLine",
    options: {
      brokers: {
        aggregated: true,
      },
      pairs: {
        onlyUsedForTrading: true,
      },
    },
    maxItems: 5,
  },
  {
    label: "Spread Graph",
    icon: StockStyled,
    graph: "spread",
    options: {
      brokers: {
        allIndividualFeeds: true,
        aggregated: true,
      },
      pairs: {
        allIndividual: true,
      },
    },
    maxItems: 5,
  },
  {
    label: "Spread Monitor",
    icon: MonitorStyled,
    graph: "spreadAverages",
    options: {
      brokers: {
        aggregated: true,
      },
      pairs: {
        allIndividual: true,
      },
    },
  },
  {
    label: "Open Positions",
    icon: CompassStyled,
    graph: "openPositions",
    options: {
      brokers: {
        tradedBrokers: true,
        aggregated: true,
        allPOP: true,
      },
      pairs: {
        aggregated: true,
      },
    },
  },
  {
    label: "Current status",
    icon: BankStyled,
    graph: "balanceEquity",
    options: {
      brokers: {
        aggregated: true,
      },
      pairs: {
        aggregated: true,
      },
    },
    maxItems: 2,
  },
  {
    label: "Currency Exposure",
    icon: PieChartStyled,
    graph: "currencyExposure",
    options: {
      brokers: {
        tradedBrokers: true,
        aggregated: true,
        allPOP: true,
      },
      pairs: {
        aggregated: true,
      },
    },
  },
  {
    label: "PEP Graph",
    icon: RocketStyled,
    graph: "pep",
    options: {
      brokers: {
        tradedBrokers: true,
        aggregated: true,
        allPOP: true,
      },
      pairs: {
        onlyUsedForTrading: true,
      },
    },
    maxItems: 5,
  },
  {
    label: "PEP Graph (Beta)",
    icon: RocketStyled,
    graph: "pep-beta",
    options: {
      brokers: {
        tradedBrokers: true,
        aggregated: true,
        allPOP: true,
      },
      pairs: {
        onlyUsedForTrading: true,
      },
    },
    maxItems: 5,
  },
  {
    label: "Quotes Freshness",
    icon: ClockStyled,
    graph: "quotesDelays",
    options: {
      brokers: {
        aggregated: true,
      },
      pairs: {
        aggregated: true,
      },
    },
    maxItems: 2,
  },
  {
    label: "Costs",
    icon: CostIconStyled,
    graph: "costs",
    options: {
      brokers: {
        aggregated: true,
      },
      pairs: {
        aggregated: true,
      },
    },
  },
  {
    label: "Volume distribution",
    icon: PopOrBrokerTableIconStyled,
    graph: "popOrBrokerTable",
    options: {
      brokers: {
        aggregated: true,
      },
      pairs: {
        aggregated: true,
      },
    },
  },
  {
    label: "Margin Exposure",
    icon: MarginExposureIconStyled,
    graph: "exposureMargin",
    options: {
      brokers: {
        aggregated: true,
      },
      pairs: {
        aggregated: true,
      },
    },
    maxItems: 2,
  },
  {
    label: "Pairs Exposure",
    icon: PairsExposureIconStyled,
    graph: "exposurePairs",
    options: {
      brokers: {
        aggregated: true,
      },
      pairs: {
        aggregated: true,
      },
    },
    maxItems: 2,
  },
  {
    label: "Pairs Volume",
    icon: PairsVolumeIconStyled,
    graph: "volumePairs",
    options: {
      brokers: {
        aggregated: true,
      },
      pairs: {
        aggregated: true,
      },
    },
    maxItems: 2,
  },
  {
    label: "Trading View Prices",
    icon: PriceChangeStyled,
    graph: "externalPrices",
    options: {
      brokers: {
        aggregated: true,
      },
      pairs: {
        aggregated: true,
      },
    },
  },
  {
    label: "Economic Calendar",
    icon: ExternalCalendarStyled,
    graph: "externalEconomicCalendar",
    options: {
      brokers: {
        aggregated: true,
      },
      pairs: {
        aggregated: true,
      },
    },
  },
  {
    label: "Market Overview",
    icon: MarketOverviewStyled,
    graph: "externalMarketOverview",
    options: {
      brokers: {
        aggregated: true,
      },
      pairs: {
        aggregated: true,
      },
    },
  },
]

export const computationalExpensiveGraphs: GraphType[] = [
  "spread",
  "balanceEquity",
  "pep",
  "pep-beta",
  "quotesDelays",
  "aiActivity",
  "aiActivity-2",
  "exposureMargin",
  "exposurePairs",
  "volumePairs",
]

export const graphsWithComm: GraphType[] = [
  "liquidity",
  "TOBDominance",
  "TOBContribution",
  "spreadAverages",
]
export const graphsWithVWAP: GraphType[] = ["liquidity"]

export const graphsWithCache = [
  "spread",
  "balanceEquity",
  "pep",
  "pep-beta",
  "aiActivity",
  "aiActivity-2",
  "aiActivityLine",
  "exposureMargin",
  "exposurePairs",
  "volumePairs",
]

export const defaultLoggedOutUser = {
  isLogged: false,
  tokens: {},
  user: {},
} as const

export const currencies = [
  "eur",
  "aud",
  "gbp",
  "jpy",
  "nzd",
  "usd",
  "cad",
  "chf",
  "nok",
  "sek",
  "sgd",
  "xau",
  "xag",
  "btc",
  "200",
  "d30",
  "e35",
  "f40",
  "225",
  "e50",
  "100",
  "u30",
  "spx",
  "nas",
  "mxn",
  "ils",
  "zar",
  "eth",
  "pln",
  "czk",
  "huf",
  "try",
  "rub",
  "hkd",
  "brl",
  "ron",
] as const

export const currenciesUppercase = [
  "EUR",
  "AUD",
  "GBP",
  "NZD",
  "USD",
  "CAD",
  "CHF",
  "NOK",
  "SEK",
  "SGD",
  "XAU",
  "XAG",
  "BTC",
  "200",
  "D30",
  "E35",
  "F40",
  "225",
  "E50",
  "100",
  "U30",
  "SPX",
  "NAS",
  "JPY",
  "MXN",
  "ILS",
  "ZAR",
  "ETH",
  "PLN",
  "CZK",
  "HUF",
  "TRY",
  "RUB",
  "HKD",
  "BRL",
  "RON",
  "",
] as const

export const unknownBroker: Broker = {
  id: "unknown",
  label: "UNKNOWN",
  isPOP: false,
  isPartOfPOP: false,
  isUsed: false,
}

export const MAX_QUOTES_RATE_MS = 40_000
