import { Chart, Series } from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { TOBContribution } from "../components/TOB-contribution"
import { TOBDominance } from "../components/TOB-dominance"
import AiActivityGraph from "../components/ai-activity-graph"
import AiActivityGraph2 from "../components/ai-activity-graph-second-proposal"
import AiActivityGraphLine from "../components/ai-activity-line-graph"
import BalanceAndEquity from "../components/balance-and-equity"
import { CostsWidget } from "../components/costs"
import CurrencyExposure from "../components/currency-exposure"
import EconomicCalendarWidget from "../components/economic-calendar"
import ExposurePair from "../components/exposure-by-pairs"
import ExposureMargin from "../components/exposure-margin"
import LiquidityBook from "../components/liquidity-book"
import MarketOverviewWidget from "../components/market-overview"
import OpenPositions from "../components/open-positions"
import PepGraph from "../components/pep-graph"
import PepGraphBeta from "../components/pep-graph-beta"
import { PopOrBrokerTableWidget } from "../components/pop-or-broker-table"
import QuotesDelays from "../components/quotes-delays"
import SpreadAverages from "../components/spread-averages/"
import SpreadCounter from "../components/spread-counter"
import SpreadGraph from "../components/spread-graph"
import TradingViewWidget from "../components/trading-widget"
import VolumePair from "../components/volume-by-pair"
import {
  AllGraphs,
  graphsMenuItems,
  pairList,
  unknownBroker,
} from "../constants"
import {
  Broker,
  BrokerId,
  BrokerPair,
  GraphInfo,
  GraphOptions,
  GraphReactComponent,
  GraphType,
  GraphsList,
  GraphsSaved,
  Pair,
  PairId,
} from "../types"

export const deleteGraphById = (graphs: GraphsList, id: string) =>
  graphs.filter(g => g.id !== id)

export const getBrokerPair = (
  broker: BrokerId | Broker,
  pair: PairId,
  comm?: boolean,
  vwap?: boolean,
): string => {
  const isBrokerId = (x: unknown): x is BrokerId => typeof x === "string"
  const parsedBrokerId = isBrokerId(broker) ? broker : broker.id

  return `${parsedBrokerId}-${pair}${comm ? "-comm" : ""}${vwap ? "-vwap" : ""}`
}

export const getGraphFromType = (type: GraphType): GraphReactComponent => {
  switch (type) {
    case "liquidity":
      return LiquidityBook
    case "spread":
      return SpreadGraph
    case "spreadCounter":
      return SpreadCounter
    case "TOBContribution":
      return TOBContribution
    case "TOBDominance":
      return TOBDominance
    case "openPositions":
      return OpenPositions
    case "balanceEquity":
      return BalanceAndEquity
    case "currencyExposure":
      return CurrencyExposure
    case "exposureMargin":
      return ExposureMargin
    case "exposurePairs":
      return ExposurePair
    case "volumePairs":
      return VolumePair
    case "pep":
      return PepGraph
    case "pep-beta":
      return PepGraphBeta
    case "quotesDelays":
      return QuotesDelays
    case "aiActivity":
      return AiActivityGraph
    case "aiActivity-2":
      return AiActivityGraph2
    case "aiActivityLine":
      return AiActivityGraphLine
    case "spreadAverages":
      return SpreadAverages
    case "costs":
      return CostsWidget
    case "popOrBrokerTable":
      return PopOrBrokerTableWidget
    case "externalPrices":
      return TradingViewWidget
    case "externalEconomicCalendar":
      return EconomicCalendarWidget
    case "externalMarketOverview":
      return MarketOverviewWidget
  }
}

const formatter = Intl.NumberFormat("en", { notation: "compact" })
export const formatVolume = (volume: number) => formatter.format(volume)

export const getIntervalLabel = ([start, end]: [number, number]) => {
  if (start === 0) return `< ${formatVolume(end)}`
  if (end === Infinity || !end || end === Number.MAX_SAFE_INTEGER)
    return `> ${formatVolume(start)}` //NB infinity gets serialized as null when turned into json

  return `${formatVolume(start)} - ${formatVolume(end)}`
}

export const createGraphsFromLocalStorage = (latestGraphs: GraphsSaved) =>
  latestGraphs
    .filter(g => AllGraphs.includes(g.type))
    .map(g => ({
      ...g,
      Graph: getGraphFromType(g.type),
    }))

export const getPairFromId = (id: PairId): Pair =>
  pairList.find(p => p.id === id)!

export const typedKeys = Object.keys as <T extends object>(
  obj: T,
) => Array<keyof T>

export const typedEntries = Object.entries as <T extends object>(
  obj: T,
) => [keyof T, T[keyof T]][]

export const zip = <T, G>(a: T[], b: G[]) =>
  a.length > b.length
    ? b.map((k, i) => [a[i], k] as [T, G])
    : a.map((k, i) => [k, b[i]] as [T, G])

export const getSerieGraphData = (
  chartComponentRef: React.RefObject<HighchartsReact.RefObject>,
  seriesName: string,
) => {
  const series: Series[] | undefined =
    chartComponentRef.current?.chart?.series?.filter(s => s.name === seriesName)

  return (series || []).map(s => {
    const graphDataX = (s as any).xData as number[]
    const graphDataY = (s as any).yData as number[]
    return zip(graphDataX, graphDataY)
  })
}

export const getGraphData = (
  chartComponentRef: React.RefObject<HighchartsReact.RefObject>,
) => {
  const series: Series[] | undefined =
    chartComponentRef.current?.chart?.series?.filter(
      s => s.options.className !== "highcharts-navigator-series",
    )
  return (series || []).map(s => {
    const graphDataX = (s as any).xData as number[]
    const graphDataY = (s as any).yData as number[]
    return zip(graphDataX, graphDataY)
  })
}

export const mergeNewData = (
  historicalData: [number, number][],
  liveData: [number, number][],
) => {
  if (liveData.length === 0) return historicalData

  const _firstOldIndex = historicalData
    .slice()
    .reverse()
    .findIndex(([timeStamp, _]) => timeStamp < liveData[0][0])
  const firstOldIndex = _firstOldIndex === -1 ? 0 : _firstOldIndex

  const newData = historicalData.slice(0, historicalData.length - firstOldIndex)
  return [...newData, ...liveData]
}

export const setGraphExtremesAndRedrawChart = (
  chartComponentRef: React.RefObject<HighchartsReact.RefObject>,
  rangeMin: number,
  rangeMax: number,
) => {
  const shift = rangeMax - rangeMin
  const dataMin = (chartComponentRef.current?.chart.series[0] as any).xData[0]
  const dataMax = (chartComponentRef.current?.chart.series[0] as any).xData.at(
    -1,
  )

  const graphMin = Math.max(rangeMin, dataMin)
  const proposedMax = Math.max(graphMin + shift, rangeMax)
  const graphMax = Math.min(proposedMax, dataMax)
  chartComponentRef.current?.chart.xAxis[0].setExtremes(
    graphMin,
    graphMax,
    true,
    false,
  )
}

//The start of the week is at 1am on Monday UTC time
export const getWeekStart = (ofDate: Date = new Date()) => {
  const beginningOfToday = ofDate.setUTCHours(1, 15, 0, 1)
  const todayWeekDayNumber = (ofDate.getUTCDay() - 1 + 7) % 7
  const millisecondsPerDay = 1_000 * 60 * 60 * 24
  return new Date(beginningOfToday - todayWeekDayNumber * millisecondsPerDay)
}

export const getGraphOptions = (type: GraphType) =>
  graphsMenuItems.find(({ graph }) => graph === type)!.options

export const isBrokerValid = (broker: Broker, graphOptions: GraphOptions) => {
  if (broker.id === "aggregated" && graphOptions.brokers.aggregated) return true

  if (broker.id !== "aggregated") {
    if (broker.isPOP && graphOptions.brokers.allPOP) return true
    if (broker.isPartOfPOP && graphOptions.brokers.allPOP) return false
    if (!broker.isPOP && graphOptions.brokers.tradedBrokers && broker.isUsed)
      return true
    if (!broker.isPOP && graphOptions.brokers.allIndividualFeeds) return true
  }

  return false
}

export const getFirstValidBroker = (
  graphOptions: GraphOptions,
  brokersList: Broker[],
) => {
  const aggregated = brokersList.find(broker => broker.id === "aggregated")
  if (graphOptions.brokers.aggregated && aggregated) return aggregated

  return (
    brokersList.find(broker => isBrokerValid(broker, graphOptions)) ||
    unknownBroker
  )
}

export const getFirstValidPair = (graphOptions: GraphOptions) => {
  if (graphOptions.pairs.aggregated) return getPairFromId("all")

  if (graphOptions.pairs.allIndividual) return pairList[0]

  return getPairFromId("all") //this should never happen
}

export const changeGraphVWAP =
  (
    graphs: GraphsList,
    setGraphs: React.Dispatch<React.SetStateAction<GraphsList>>,
    graphInfo: GraphInfo,
  ) =>
  () => {
    setGraphs(
      graphs.map(g => {
        if (g.id !== graphInfo.id) return g
        return {
          ...g,
          vwap: !g.vwap,
        }
      }),
    )
  }

export const changeGraphComm =
  (
    graphs: GraphsList,
    setGraphs: React.Dispatch<React.SetStateAction<GraphsList>>,
    graphInfo: GraphInfo,
  ) =>
  () => {
    setGraphs(
      graphs.map(g => {
        if (g.id !== graphInfo.id) return g
        return {
          ...g,
          comm: !g.comm,
        }
      }),
    )
  }

export const deleteAllAnnotations = (chart: Chart) => {
  if ((chart as any).annotations.length === 0) return
  ;(chart as any).annotations.forEach(async (annotation: any) =>
    annotation.destroy(),
  )
  ;(chart as any).annotations.length = 0
  return
}

export const asyncFlatMap = async <In, Out>(arr: In[], f: (x: In) => Out) => {
  const out = await Promise.all(arr.map(f))
  return out.flat()
}

export const getBaseCurrency = (pairId: PairId) => pairId.slice(3, 6)
export const getQuoteCurrency = (pairId: PairId) => pairId.slice(6, 9)

export const getPairStrip = (pair: string) => {
  if (pair === "aggregated-all" || pair === "all") return "SUM"
  else if (pair === "pairs") return "PAIRS"
  else return pair.split("_")[1].toUpperCase()
}

export const getPair = (brokerPair: BrokerPair) =>
  brokerPair.split("-")[1] as PairId

const getRandomColor = () => {
  let randomColorString = "#"
  const arrayOfColorFunctions = "0123456789abcdef"
  for (let x = 0; x < 6; x++) {
    let index = Math.floor(Math.random() * 16)
    let value = arrayOfColorFunctions[index]

    randomColorString += value
  }
  return randomColorString
}

export const getColor = (
  colorsMapper: Record<string, string>,
  colorKey: string,
) => colorsMapper[colorKey.toUpperCase()] ?? getRandomColor()
